<template>
	<v-container class="fill-height" fluid>
		<v-row align="center" justify="center">
			<v-col cols="12" sm="8" md="6">
				<v-card class="elevation-20">
					<v-toolbar color="primary" dark flat>
						<v-toolbar-title>{{ $t('passwordReset') }}</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
					<v-card-text>
						<v-form ref="form">
							<v-text-field
								id="password"
								:label="$t('newPassword')"
								name="password"
								prepend-icon="mdi-lock"
								type="password"
								v-model="password"
								:rules="passwordRules"
								required
							></v-text-field>

							<v-text-field
								id="confirm-password"
								:label="$t('confirmPassword')"
								name="confirmPassword"
								prepend-icon="mdi-lock"
								type="password"
								v-model="confirmPassword"
								:rules="confirmPasswordRules"
								required
							></v-text-field>

							<!-- Error Messages -->
							<v-alert v-model="confirmPassErr" type="error" dense dismissible>
								{{ $t('confirmPasswordError') }}
							</v-alert>
							<v-alert v-model="generalErr" type="error" dense dismissible>
								{{ $t('generalError') }}
							</v-alert>
							<v-alert v-model="badPassword" type="error" dense dismissible>
								{{ $t('badPassword') }}
							</v-alert>
							<v-alert v-model="expiredToken" type="error" dense dismissible>
								{{ $t('invalidToken') }}
								<router-link
									class="px-2 reset-password"
									to="/forgot-password"
									>{{ $t('passwordReset') }}</router-link
								>
							</v-alert>

							<!-- Success Message -->
							<v-alert v-model="successMsg" type="error" dense dismissible>
								{{ $t('passwordResetSuccess') }}
								<router-link to="/login">{{ $t('login') }}</router-link>
							</v-alert>
						</v-form>

						<!-- Loader -->
						<div v-if="isLoading" class="text-center">
							<v-progress-circular
								indeterminate
								color="primary"
							></v-progress-circular>
						</div>
					</v-card-text>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="primary" @click="onSubmit">{{ $t('submit') }}</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'RegisterForm',

	data() {
		return {
			url: process.env.VUE_APP_BASE_URL || 'http://localhost:5000/api/v1',
			password: '',
			confirmPassword: '',
			successMsg: false,
			confirmPassErr: false,
			badPassword: false,
			expiredToken: false,
			generalErr: false,
			isLoading: false,
			passwordRules: [v => !!v || this.$i18n.t('requiredField')],
			confirmPasswordRules: [v => !!v || this.$i18n.t('requiredField')],
		};
	},
	methods: {
		async onSubmit() {
			this.confirmPassErr = false;
			this.badPassword = false;
			this.generalErr = false;

			this.$refs.form.validate();

			if (!this.$refs.form.validate()) {
				return;
			}

			if (this.password !== this.confirmPassword) {
				this.confirmPassErr = true;
				return;
			}

			this.isLoading = true;

			try {
				const res = await this.$http.put(
					`${this.url}/auth/resetpassword/${this.$route.params.resettoken}`,
					{
						password: this.password,
					},
				);

				if (res.data.error && res.data.error === 'Invalid token') {
					this.expiredToken = true;
				}

				this.password = '';
				this.confirmPassword = '';
				this.$refs.form.resetValidation();
				this.isLoading = false;
				this.successMsg = true;
			} catch (error) {
				console.error(error);
				this.isLoading = false;

				if (
					error.response.data.error.includes('ValidationError') ||
					error.response.data.error.includes('Path')
				) {
					this.badPassword = true;
				} else if (
					error.response.data.error &&
					error.response.data.error === 'Invalid token'
				) {
					this.expiredToken = true;
				} else {
					this.generalErr = true;
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.reset-password {
	background: #fff;
	color: #f0ae1d;
	border-radius: 5px;
}
</style>
